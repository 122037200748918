import React, { useState, useEffect } from "react";
import { SimpleForm } from "../../../components/com/form/";
import { Checkbox, message } from 'antd';
import { getService } from "../../../services/";
const service = getService("properties-amenities");
const options = [
    { label: 'Apple', value: 'Apple' },
    { label: 'Pear', value: 'Pear' },
    { label: 'Orange', value: 'Orange' },
];
const Form = ({ record, ...props }) => {
    const [amenities, setAmenities] = useState([]);
    const getAmenities = () => {
        const service = getService("amenities");
        service.find({
            query: {
                $limit: 999999999,
                $sort: { name: 1 }
            }
        })
            .then(({ data }) => {
                setAmenities(data);
            })
            .catch(err => message.error(err.message));

    }
    const saveAmenities = async (amenities_ids) => {
        await service.create({
            property_id: record.id,
            amenities_ids
        }).then(response => {
            message.success("Ameneidades Actualizadas!");
        })
            .catch(err => message.error(err.message));
    }
    const onChange = async (field, amenities_ids) => {
        saveAmenities(amenities_ids);
    }
    useEffect(() => {
        getAmenities();
    }, [])
    return (<SimpleForm
        /*  onChange={props.onChange} */
        autoSubmit={false}>
        <Checkbox.Group
            onChange={onChange}
            options={amenities.map(it => ({
                label: it.name,
                value: it.id
            }))}
            initial={record.amenities.map(it => it.id)}
            /*  onChange={onChange} */
            flex={1}
        />
    </SimpleForm>)
}
export default Form;