import React, { useEffect, useState } from "react";
import { SimpleForm, SelectField } from "../../../components/com/form/";
import { Switch, Input, InputNumber, Divider, message } from 'antd';
import { getService } from "../../../services/";
/* 'residential','commercial' */
const use_types = [
    {
        id: "residential",
        name: "Residencial"
    },
    {
        id: "commercial",
        name: "Comercial"
    },
]
const PricesForm = ({ record, onChange, ...props }) => {

    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const getTypes = () => {
        const service = getService("types");
        service.find({
            query: {
                $sort: { name: 1 }
            }
        })
            .then(({ data }) => {
                setTypes(data);
                setLoading(false);
            })
            .catch(err => message.error(err.message));
    }
    useEffect(() => {
        getTypes();
        return () => {
        }
    }, []);

    return (
        <>
            {!loading && <SimpleForm
            onChange={onChange}
            initialValues={record} autoSubmit={false}>
                <InputNumber
                    name="area_m2"
                    min={0} placeholder="Area m2" label="Area m2" flex={.33} />
                <SelectField
                    name="type_id"
                    choices={types} min={0} placeholder="Tipo" label="Tipo" flex={.33} />
                <SelectField
                    name="use_type"
                    choices={use_types}
                    min={0} placeholder="Tipo de Uso" label="Tipo de Uso" flex={.33} />

                <InputNumber name="rooms" min={0} placeholder="Numero de Cuartos" label="Numero de Cuartos" flex={.33} />
                <InputNumber name="bathrooms" min={0} placeholder="Numero de Baños" label="Numero de Baños" flex={.33} />
                <InputNumber name="parking_slots" min={0} placeholder="Numero de Garajes" label="Numero de Garajes" flex={.33} />

                <Switch name="service_room"
                    label="¿Cuarto de Servicio?"
                    flex={.33}
                    defaultChecked={(record.service_room === "true")} />
                <Switch name="balcony"
                    label="¿Balcón/Terraza?"
                    flex={.33}
                    defaultChecked={(record.balcony === "true")}
                />

                <InputNumber name="balcony_area_m2" min={0} placeholder="Area Balcón/Terraza" label="Area Balcón/Terraza" flex={.33} />
                <Divider dashed style={{ margin: 0 }} flex={1} />
                <Input.TextArea name="description" flex={1} placeholder="Aquí va un parrafo que describa el proyecto..." label="Descripción" />
            </SimpleForm>}
        </>
    )
}
export default PricesForm;