import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { SimpleForm, SelectField } from "../../../components/com/form/";
import { Checkbox, message } from 'antd';
import { getService } from "../../../services/";
const service = getService("properties-amenities");

/* 

'owner','agency','worke','independent realtor'
*/
const types = [
    {
        name: "Propietario",
        id: "owner"
    },
    {
        name: "Agencia",
        id: "agency"
    },
    {
        name: "Trabajador",
        id: "worke"
    },
    {
        name: "Independiente",
        id: "independent"
    },
    {
        name: "Relator",
        id: "realtor"
    },
];
const Form = ({ record, id, onSubmit, ...props }) => {
    const savePropertyContact = async (contact) => {
        const service = getService("properties-contacts");
        if (!id)
            await service.create({
                property_id: record.id,
                contact_id: contact.id
            }).then(response => {
                if (onSubmit) onSubmit(contact);
                message.success("Contacto Creado!");
            })
                .catch(err => message.error(err.message));
    }
    useEffect(() => {
    }, [])
    return (<SimpleForm
        id={id}
        onSubmit={savePropertyContact}
        source="contacts"
        /*  onChange={props.onChange} */
        autoSubmit={true}>
        <SelectField
            flex={1}
            name="type"
            placeholder="Tipo"
            label="Tipo"
            choices={types} />
        <Input
            name="name"
            flex={1}
            placeholder="Nombre"
            label="Nombre"
        />
        <Input
            name="email"
            placeholder="Correo Electrónico"
            label="Correo Electrónico"
        />
        <Input
            name="cellphone"
            placeholder="Teléfono"
            label="Teléfono"
        />
        <Input.TextArea
            name="notes"
            flex={1}
            placeholder="Notas"
            label="Notas"
        />


    </SimpleForm>)
}
export default Form;