import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { SimpleForm, SelectField } from "../../../components/com/form/";
import { Checkbox, message } from 'antd';
import { getService } from "../../../services/";
const service = getService("properties-amenities");

/* 

'owner','agency','worke','independent realtor'
*/
const types = [
    {
        name: "Propietario",
        id: "owner"
    },
    {
        name: "Agencia",
        id: "agency"
    },
    {
        name: "Trabajador",
        id: "worke"
    },
    {
        name: "Independiente",
        id: "independent"
    },
    {
        name: "Relator",
        id: "realtor"
    },
];
const Form = ({ record, id, onSubmit, ...props }) => {
    const [third_parties, setThirdParties] = useState([]);
    const savePropertyContact = async (contact) => {
        const service = getService("properties-third-party-contacts");
        if (!id)
            return service.create({
                property_id: record.id,
                third_party_contact_id: contact.id
            }).then(response => {
                if (onSubmit) onSubmit(contact);
                message.success("Contacto Creado!");
            })
                .catch(err => message.error(err.message));
        if (onSubmit) onSubmit();
    }
    const getThirdParties = () => {
        const service = getService("third-parties");
        service.find({}).then(({ data }) => {
            setThirdParties(data);
        })
            .catch(err => message.error(err.message));
    }
    useEffect(() => {
        getThirdParties();
    }, [])
    return (<SimpleForm
        id={id}
        onSubmit={savePropertyContact}
        source="third-party-contacts"
        /*  onChange={props.onChange} */
        autoSubmit={true}>
        <SelectField
            flex={1}
            name="third_party_id"
            placeholder="Tercero"
            label="Tercero"
            choices={third_parties} />
        <Input
            name="name"
            flex={1}
            placeholder="Nombre"
            label="Nombre"
        />
        <Input
            name="email"
            placeholder="Correo Electrónico"
            label="Correo Electrónico"
        />
        <Input
            name="phone"
            placeholder="Teléfono"
            label="Teléfono"
        />
        <Input.TextArea
            name="notes"
            flex={1}
            placeholder="Notas"
            label="Notas"
        />


    </SimpleForm>)
}
export default Form;