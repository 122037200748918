import React, { useState, useEffect } from "react";
import { SimpleForm, SelectField } from "../../../components/com/form/";
import MapBox from "../../../components/com/map/MapBox";
import { getService } from "../../../services/";
import { Switch, Input, InputNumber, Divider, Row, Col, message } from 'antd';
const Form = ({ record, ...props }) => {
    const [state, setState] = useState({});
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [lngLat, setLngLat] = useState([]);
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [city_id, setCityId] = useState(record.city_id);

    const handleOnChange = (field, value, form) => {
        setState({
            ...state,
            [field]: value
        });
        if (field)
            alert("Record:" + field + JSON.stringify(value));
    }

    const getNeighborhoods = (city_id) => {
        const service = getService("neighborhoods");
        service.find({
            query: {
                $sort: { name: 1 },
                $limit: 500000000,
                city_id
            }
        })
            .then(({ data }) => {
                setNeighborhoods(data);
                setLoading(false);
            })
            .catch(err => message.error(err.message));
    }
    const getCities = () => {
        const service = getService("cities");
        service.find({
            query: {
                $sort: { name: 1 }
            }
        })
            .then(({ data }) => {
                setCities(data);
                setLoading(false);
            })
            .catch(err => message.error(err.message));
    }
    useEffect(() => {
        setLngLat([
            {
                _geoloc: {
                    lat: parseFloat(record.lat),
                    lng: parseFloat(record.lng),
                }
            }
        ])
        getCities();
    }, []);
    useEffect(() => {
        if (city_id)
            getNeighborhoods(city_id);
    }, [city_id]);
    return (<>{!loading && <SimpleForm
        onChange={props.onChange}
        initialValues={record}
        autoSubmit={false}>
        <SelectField name="city_id"
            choices={cities}
            onChange={(field, value) => {
                setNeighborhoods([]);
                setCityId(value);
            }}
            initial={record.city_id} min={0} placeholder="Ciudad" label="Ciudad" flex={.33} />
        <SelectField
            name="neighborhood_id"
            choices={neighborhoods}
            initial={record.neighborhood_id}
            min={0} placeholder="Barrio"
            label="Barrio"
            flex={.33} />

        <InputNumber name="stratum" min={0} placeholder="Estrato" label="Estrato" flex={.33} />


        <Input.TextArea name="address" placeholder="Dirección" label="Dirección" flex={1} />
        <Divider dashed style={{ margin: 0 }} flex={1} />

        <InputNumber
            name="lat"
            label="Latitud"
            placeholder="Latitud"
            onChange={(key, value, form) => {
                let { lng } = form.getFieldsValue();
                setLngLat([
                    {
                        _geoloc: {
                            lat: parseFloat(value),
                            lng: parseFloat(lng),
                        }
                    }
                ]);
            }}
        />
        <InputNumber name="lng" name="lng" label="Longitud" placeholder="Longitud"
            onChange={(key, value, form) => {
                let { lat } = form.getFieldsValue();
                setLngLat([
                    {
                        _geoloc: {
                            lat: parseFloat(lat),
                            lng: parseFloat(value),
                        }
                    }
                ]);
            }}
        />

        {record.lat && <MapBox
            name="_geoloc"
            xtype="map"
            data={lngLat}
            onChange={(key, lngLat) => {
                let { lat, lng } = lngLat;
                setLngLat([
                    {
                        _geoloc: {
                            lat: parseFloat(lat),
                            lng: parseFloat(lng),
                        }
                    }
                ]);
            }}
            flex={1}
        />}
    </SimpleForm>}
    </>)
}
export default Form;