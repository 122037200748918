import React, { useState } from "react";
import { SimpleForm, SelectField, SearchField, } from "../../../components/com/form/";
import { Switch, Input, InputNumber, Divider, Button, Table, Row, Col, AutoComplete, message, Modal } from 'antd';
import { HeadLine } from "../../../components/com";
import { getService } from "../../../services/";
const { Option } = AutoComplete;
const { confirm } = Modal;
const types = [
    {
        id: "owner",
        name: "Dueño"
    }
]
const data = [
    {
        first_name: "Daniel",
        last_name: "Vengoechea",
        phone: "3013393783",
        type: "owner"
    },
    {
        first_name: "Fabian",
        last_name: "Vargas",
        phone: "3042052067",
        type: "owner"
    }
];
const columns = [
    {
        dataIndex: "third_party_contacts.name",
    },
    {
        dataIndex: "third_party_contacts.third_party.name",
    },
]
const PricesForm = ({ onNew, onEdit, record, onUpdate, contacts = [], ...props }) => {
    const [third_party_contact_id, setContactId] = useState();
    const handleSelect = value => {
        setContactId(value);
    }
    const handleAsosiate = () => {
        const service = getService("properties-third-party-contacts");
        service.create({
            third_party_contact_id: parseInt(third_party_contact_id),
            property_id: record.id
        })
            .then(response => {
                message.success("Contacto asociado con éxito!");
                onUpdate()
            })
            .catch(err => message.error(err.message));
    }
    const handleDelete = id => {
        if (id) {
            confirm({
                title: "Atención",
                size: "small",
                content: "Desea eliminar el registro?",
                onOk() {
                    const service = getService("properties-third-party-contacts");
                    service.remove(id)
                        .then(response => {
                            message.info("Registro eliminado");
                            if (onUpdate) onUpdate();
                        })
                        .catch(err => message.error(err.message));
                },
                onCancel() { }
            });
        }
    };
    return (
        <Row>
            <Col>
                <Row style={{
                    margin: "10px 0px"
                }}
                    type="flex"
                    justify="space-between"
                    align="middle"
                    gutter={8}
                >
                    <Col xxl={12} xl={24} lg={24} md={24} xs={24}>
                        <SearchField
                            render={(value, record) => (`${value} - ${record.third_party.name}`)}
                            onSelect={handleSelect}
                            searchKey="data_value"
                            source="third-party-contacts"
                            size="large" style={{ width: "100%" }}
                            placeholder="Contacto"
                            label="Buscar Contacto" />

                    </Col>
                    <Col xxl={12} xl={24} lg={24} md={24} xs={24} style={{
                        margin: "10px 0px"
                    }}>
                        <Button
                            onClick={handleAsosiate}
                            size="large" type="primary">
                            ASOCIAR
                            </Button>
                        <Button
                            onClick={onNew}

                            style={{ marginLeft: 4 }} size="large" type="dashed">
                            CREAR NUEVO
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col>
                <Row type="flex" justify="center" align="top">
                    <Col span={24}>
                        <HeadLine size="medium">
                            Contactos Terceros
                        </HeadLine>
                        <Table
                            size="small"
                            showHeader={false}
                            pagination={false}
                            dataSource={contacts}
                            scroll={{
                                y: 300
                            }}
                            columns={[...columns,
                            {
                                width: 80,
                                render: record => (
                                    <Row type="flex" justify="space-between" align="middle">
                                        <Col>
                                            <Button
                                                onClick={() => {
                                                    onEdit(record.third_party_contacts.id)
                                                }}
                                                size="small" type="link" shape="circle" icon="eye" />
                                        </Col>
                                        <Col>
                                            <Button
                                                onClick={() => handleDelete(record.id)}
                                                size="small" type="link" shape="circle" icon="delete" />
                                        </Col>
                                    </Row>
                                )
                            }
                            ]} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default PricesForm;