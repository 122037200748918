import React, { useState } from "react";
import { SimpleForm, SelectField, SearchField } from "../../../components/com/form/";
import { Switch, Input, InputNumber, Divider, Button, Table, Row, Col, Modal, message } from 'antd';
import { getService } from "../../../services/";
const { confirm } = Modal;
const types = [
    {
        id: "owner",
        name: "Dueño"
    }
]
const data = [
    {
        first_name: "Daniel",
        last_name: "Vengoechea",
        phone: "3013393783",
        type: "owner"
    },
    {
        first_name: "Fabian",
        last_name: "Vargas",
        phone: "3042052067",
        type: "owner"
    }
];
const columns = [
    {
        dataIndex: "name",
    },
    {
        dataIndex: "type"
    }
]
const PricesForm = ({ onNew, onEdit, onUpdate, contacts = [], record, ...props }) => {
    const [contact_id, setContactId] = useState();
    const handleDelete = id => {
        if (id) {
            confirm({
                title: "Atención",
                size: "small",
                content: "Desea eliminar el registro?",
                onOk() {
                    const service = getService("properties-contacts");
                    service.remove(id)
                        .then(response => {
                            message.info("Record deleted");
                            if (onUpdate) onUpdate();
                        })
                        .catch(err => message.error(err.message));
                },
                onCancel() { }
            });
        }
    };
    const handleSelect = value => {
        setContactId(value);
    }
    const handleAsosiate = () => {
        const service = getService("properties-contacts");
        service.create({
            contact_id: parseInt(contact_id),
            property_id: record.id
        })
            .then(response => {
                message.success("Contacto asociado con éxito!");
                onUpdate()
            })
            .catch(err => message.error(err.message));
    }
    return (
        <Row>
            <Col>
                <Row style={{
                    margin: "10px 0px"
                }}
                    type="flex"
                    justify="space-between"
                    align="middle"
                    gutter={4}
                >
                    <Col xxl={12} xl={24} lg={24} md={24} xs={24}>
                        <SearchField
                            onSelect={handleSelect}
                            searchKey="data_value" source="contacts" size="large" style={{ width: "100%" }} placeholder="Contacto" label="Buscar Contacto" />
                    </Col>
                    <Col xxl={12} xl={24} lg={24} md={24} xs={24} style={{
                        margin: "10px 0px"
                    }}>
                        <Button
                            onClick={handleAsosiate}
                            size="large" type="primary">
                            ASOCIAR
                            </Button>
                        <Button
                            onClick={onNew}
                            style={{ marginLeft: 4 }} size="large" type="dashed">
                            CREAR NUEVO
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col>
                <Row type="flex" justify="center" align="top">
                    <Col span={24}>
                        <Table
                            size="small"
                            showHeader={false}
                            pagination={false}
                            dataSource={contacts}
                            columns={[...columns,
                            {
                                width: 80,
                                render: record => (
                                    <Row type="flex" justify="space-between" align="middle">
                                        <Col>
                                            <Button
                                                onClick={() => {
                                                    onEdit(record.contact_id)
                                                }}
                                                size="small" type="link" shape="circle" icon="eye" />
                                        </Col>
                                        <Col>
                                            <Button
                                                onClick={() => handleDelete(record.id)}
                                                size="small" type="link" shape="circle" icon="delete" />
                                        </Col>
                                    </Row>
                                )
                            }
                            ]} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default PricesForm;