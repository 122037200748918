import React from "react";
import { SimpleForm } from "../../../components/com/form/";
import { Switch, Input, InputNumber, Divider } from 'antd';
const Form = ({ record, ...props }) => {
    return (<SimpleForm
        onChange={props.onChange}
        initialValues={record}
        autoSubmit={false}>
        <Input.TextArea name="private_notes" placeholder="Aquí va un parrafo con notas privadas..."
            label="Notas" flex={1} />
    </SimpleForm>)
}
export default Form;