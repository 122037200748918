import { Drawer } from "antd";
import styled from 'styled-components';

export const SideBar = styled(Drawer)`
    & .ant-form{
        width: 100%!important;
        padding: 40px 0px!important;
        box-shadow: none!important;
    }
`
export const Wrapper = styled.div`
    margin:0px;
    padding:8px;

    & .ant-layout{
        background:transparent!important;
        padding:10px;
    }
    & .ant-layout-header h2{
        color: #fff!important;
    }
    & .ant-layout-header{
        background: #3e4545!important;
        color: #fff!important;
    }
    /* Head */
    .head{
        background: #f6f6f6;
        border: 1px solid #70707025;
        padding: 0px 10px 0px 0px;
        margin: 0px 5px;
        margin-bottom:10px;
        min-height: 50px;
    }

    /* Box */
    & .box{
        box-shadow:0px 3px 6px rgba(0,0,0,0.05);
        margin:8px;
        padding:0px;
        padding-left: 0px!important;
        padding-right: 0px!important;
        border: 1px solid #70707025;
    }
    & .box .box-head .ant-divider{
        margin: 1px 0!important;
    }
    & .box .box-head .headline{
        margin: 4px!important;
    }
    & .box .box-head{
        padding:0px!important;
        background: #f6f6f6;
    }
    & .box .box-head h2{
        font-size:20px;
        text-transform:uppercase;
        font-weight:bold;
        color:#9FD1C7!important;
    }
    & .box .box-body{
        padding: 10px!important;
        background: #fff;
    }

    /* Forms */
    & .ant-form{
        width: 100%!important;
        background: transparent!important;
        padding: 8px!important;
        box-shadow: none!important;
        margin: 0px!important;
    }
    & .ant-form label {
        font-size: 12px!important;
    }
    & .ant-form-item {
        padding-bottom: 0px !important;
        margin-bottom: 2px !important;
    }

`;
export const WrapperEdit = styled.div`
    & input:hover{
        background: #e5ecee!important
    }
    & input{
        border-radius: 5px!important;
        background: #f4f7f8!important;
        transition: all .25s ease!important;
        border: 2px solid transparent!important;
        color: #546067!important;
    }
    .ant-input:hover,
    .ant-input:focus,
    .ant-input:active
     {
        border: 2px solid transparent!important;
        box-shadow: none!important;
        background: #e5ecee!important;
    }
`;