import React from "react";
import { SimpleForm } from "../../../components/com/form/";
import { Switch, Input, InputNumber, Divider } from 'antd';
const PricesForm = ({ record, ...props }) => {
    return (<SimpleForm
        record={record}
        onChange={props.onChange}
        autoSubmit={false}>
        <Switch name="selling" labelAlign="left" label="¿En Venta?"
            defaultChecked={(record.selling === "true")} />
        <Switch name="renting" labelAlign="left" label="¿En Arriendo?"
            defaultChecked={(record.renting === "true")}
        />
        <InputNumber name="sale_price"
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            initial={record.sale_price}
            min={0}
            placeholder="Valor Venta"
            label="Valor Venta"
            flex={.33} />
        <InputNumber
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            name="rent_price" initial={record.rent_price}
            min={0}
            placeholder="Valor Arriendo" label="Valor Arriendo" flex={.33} />
        <InputNumber
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            name="management_price" initial={record.management_price} min={0} placeholder="Valor Administración" label="Valor Administración" flex={.33} />
    </SimpleForm>)
}
export default PricesForm;