import React, { useState } from "react";
import { message, List, Avatar, InputNumber, Card, Button, Divider } from "antd";
import { DragAndDropUploader } from "../../../components/com";
import { getService } from "../../../services/";
import { URL_S3 } from "../../../constants/";
import styled from "styled-components";
import _ from 'lodash'
import { useEffect } from "react";
const { Meta } = Card;
const MyList = styled(List)`
    & .ant-card-bordered{
        /* height:150px!important;    */
    }
    & .ant-card-body{
        padding:4px 0px!important;
    }
    & .ant-card-meta-description{
        margin: 10px!important;
    }
    & .ant-card-cover {
        height: 80px!important;
        background: #ccc;
        overflow: hidden!important;
    }
    & .ant-card-meta-description > div{
        width:100%!important;
    }
    & .ant-card-bordered ul.ant-card-actions{
        display:none!important;
    }
    & .ant-card-bordered .ant-btn{
        background: #fff!important;
        color: red!important;
    }
    & .ant-card-bordered:hover ul.ant-card-actions {
        position: absolute;
        top: 0;
        background: transparent;
        width: 100%;
        background: #0c0c0c75;
        min-height: 82px;
        display: flex!important;
        justify-content: space-between;
        align-items: center;
    }
`;
const service = getService("media")
const PropertyGalleryUploader = ({ dataSource, onChange, record, ...props }) => {
    const [media, setMedia] = useState([]);
    const [loading, setLoading] = useState(false);

    const UpdateListing = file => {
        const service = getService("media");
        if (file.key)
            service.create({
                property_id: record.id,
                path: file.key,
                type: file.type,
                priority: file.index
            })
                .then(response => {
                    getMedia();
                    /* setMedia([
                        ...media,
                        response
                    ].filter(it => (it.priority))); */
                })
                .catch(err => message.error(err.message));
    };
    const handleDelete = id => {
        const service = getService("media");
        service.remove(id)
            .then(response => {
                getMedia();
                message.info("Media eliminado!");
            })
            .catch(err => message.error(err.message));
    }
    const handleOnChange = ({ key, type, ...file }) => {
        delete file.url;
        let files = media;
        file["uid"] = `${files.length}`;
        file["key"] = key;
        file["type"] = type;
        file["url"] = file.location;
        file["path"] = file.location;
        file["status"] = file.status === 204 ? "done" : "error";
        files.push(file);
        UpdateListing(file);
    };
    const handlePriority = (value, record) => {
        setMedia([]);
        if (record.id)
            service.patch(record.id, {
                priority: value
            })
                .then(response => {
                    /*  if (onChange) onChange(); */
                    getMedia();
                    message.success("Prioridad Actualizada")
                })
                .catch(err => message.error(err.message));
    }
    const getMedia = () => {
        setLoading(true);
        service.find({
            query: {
                property_id: record.id,
                $limit: 999999999,
                $sort: {
                    priority: -1
                }
            }
        })
            .then(({ data }) => {
                setLoading(false);
                setMedia(data);
            })
            .catch(err => message.error(err.message));
    }
    var handleOnPriority = _.debounce(handlePriority, 1000, { 'maxWait': 1000 });
    const renderItems = (record, index) => {
        let url = record.path || record.url;
        if (url && url.indexOf(URL_S3) == -1)
            url = `${URL_S3}/${url}`;
        return (<List.Item>
            <Card
                actions={[<Button
                    shape="circle"
                    type="ghost"
                    icon="delete"
                    onClick={() => handleDelete(record.id)} />]}
                cover={<img src={url} />}>
                <Meta
                    description={<>
                        <span>Prioridad</span>
                        <InputNumber
                            min={0}
                            defaultValue={record.priority}
                            name="priority" onChange={(value) => handleOnPriority(value, record)} />
                    </>}
                />
            </Card>
        </List.Item>);
    }
    useEffect(() => {
        getMedia();
    }, []);
    return (<>
        <DragAndDropUploader
            dataSource={media}
            onChange={handleOnChange}
            /*onDelete={handleOnDelete} */
            path={`/properties/${record.id}`}
        />
        <Divider type="horizontal" dashed />
        <MyList
            loading={loading}
            grid={{ gutter: 8, column: 3 }}
            renderItem={renderItems}
            dataSource={media.map((it, key) => ({
                uid: key,
                url: it.path,
                status: "done",
                name: key,
                index: it.priority,
                ...it
            }))}
        />
    </>);
}
export default PropertyGalleryUploader;