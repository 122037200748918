import React from "react";
import { SimpleForm } from "../../../components/com/form/";
import { InputNumber } from 'antd';

const Form = ({ record, ...props }) => {
    return (<SimpleForm
        onChange={props.onChange}
        initialValues={record}
        autoSubmit={false}>
        <InputNumber name="position" label="Posición" placeholder="Posición" />
        <InputNumber name="priority" label="Nivel Destacado" placeholder="Nivel Destacado" />
    </SimpleForm>)
}
export default Form;