import React, { useEffect, useState, useRef } from 'react';
import { Col, Row, Layout, Divider, message, Empty, Spin, Icon, Drawer, Switch, Input, Button } from "antd";
import { Wrapper, SideBar } from "./Styles";
import { HeadLine } from '../../components/com';
import { getService } from "../../services/";
import _ from 'lodash'
import {
    PricesForm, GeneralInformationForm, UbicationForm, NotesForm,
    ContactsForm,
    ContactForm,
    ThirdPartiesForm,
    AmenitiesForm,
    OthersForm,
    PropertyGallery,
    PropertyGalleryUploader,
    ThirdPartyContactForm
} from "./views/";
import Loader from '../../components/loader';
import { Link } from 'react-router-dom';
import { WrapperEdit } from "./Styles";
const { Content, Header } = Layout;
const Box = ({ children, title, ...props }) => (<Col className={props.className || "box"}>
    {title && <Row className="box-head">
        <Col>
            <HeadLine size="medium">{title}</HeadLine>
            <Divider />
        </Col>
    </Row>}
    <Row className="box-body">
        <Col>
            {children}
        </Col>
    </Row>
</Col>)
const data = [
    {
        uid: "-1",
        url: "https://imagecdn.gooneworld.com/394461783/0.jpg",
        name: 'image.png',
        status: 'done',
    },
    {
        uid: "-2",
        url: "https://imagecdn.gooneworld.com/394461783/2.jpg",
        name: 'image2.png',
        status: 'done',
    },
    {
        uid: "-3",
        url: "https://imagecdn.gooneworld.com/394461783/3.jpg",
        name: 'image3.png',
        status: 'done',
    },
    {
        url: "https://imagecdn.gooneworld.com/394461783/4.jpg",
        name: 'image4.png',
        status: 'done',
    },
];

const service = getService("properties");

const Title = ({ children, record, onChange, ...props }) => {
    const [editable, setEditable] = useState(false);
    const [value, setValue] = useState(record.name);
    const myRef = useRef(null);

    useEffect(() => {
        if (myRef.current && editable)
            myRef.current.focus();
    }, [editable])
    return (<>
        {!editable ? <HeadLine size="medium">
            <Link to="/properties">
                <Icon type="arrow-left" />{" "}
            </Link>
            {children}
            <Button icon="edit" type="link" onClick={() => setEditable(editable => !editable)} />
        </HeadLine> :
            <WrapperEdit>
                <Input
                    ref={myRef}
                    size="large"
                    value={value}
                    onChange={e => {
                        let { value } = e.target;
                        setValue(value);
                    }}
                    onPressEnter={e => {
                        let { value } = e.target;
                        setValue(value);
                        if (onChange)
                            onChange("name", value);
                        setEditable(false)
                    }}
                    defaultValue={value}
                    style={{
                        width: 350,
                        margin: 4
                    }}
                    placeholder="Escribe el nombre de la propiedad" />
                <Button icon="save" type="link" onClick={() => {
                    if (onChange)
                        onChange("name", value);
                    setEditable(false)
                }} />
                <Button icon="close" type="link" onClick={() => {
                    if (onChange)
                        setEditable(false)
                }} />
            </WrapperEdit>
        }
    </>)
}
const PropertyShow = (props) => {
    const [record, setRecord] = useState();
    const [action, setAction] = useState();
    const [payloads, setPayloads] = useState();
    const [loading, setLoading] = useState(true);
    const [contact_id, setContactId] = useState(true);

    const getData = () => {
        let { match } = props;
        setContactId(null);
        setAction(null);
        if (match.params) {
            let { id } = match.params;
            console.log("PARAMS: ", match.params);
            setLoading(true);
            service.get(id)
                .then(record => {
                    setLoading(false);
                    if (record)
                        setRecord(record);
                })
                .catch(err => message.error(err.message));
        }
    }
    const handleNew = action => {
        setContactId(null);
        setAction(action);
    }
    const handleEdit = (action, id) => {
        setContactId(id);
        setAction(action);
    }
    const onChange = (field, value) => {
        console.log(field + ":" + value);
        value = typeof value == "boolean" ? String(value) : value;

        if (field == "_geoloc") {
            let { lat, lng } = value;
            lat = String(lat);
            lng = String(lng);
            return setPayloads({
                //[field]: value,
                lng,
                lat
            });
        }
        setRecord({
            ...record,
            [field]: value
        });
        setPayloads({
            [field]: value
        });
    };
    const saveProperty = payloads => {
        let { match } = props;
        if (match.params) {
            let { id } = match.params;
            service.patch(id, payloads)
                .then(record => {
                    message.success("Propiedad Actualizada!")
                })
                .catch(err => {
                    message.error(err.message);
                });
        }
    }
    const getContacts = () => {
        const service = getService("contacts");
        /*  service.find() */
    }
    var handleOnChange = _.debounce(onChange, 1000, { 'maxWait': 1000 });
    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        if (payloads) {
            console.log("PAYLOADS: ", payloads)
            saveProperty(payloads);
        }
    }, [payloads]);
    return (<>
        <Wrapper>
            {!loading && record ? <Layout>
                <Row className="head" type="flex" justify="space-between" align="middle">
                    <Col>
                        <Title
                            record={record}
                            onChange={handleOnChange}>
                            {record.name}
                        </Title>
                    </Col>
                    <Col>
                        <span>Activar/Desactivar</span>{" "}
                        <Switch
                            onChange={(value) => { handleOnChange("status", value ? "active" : "unactive") }}
                            defaultChecked={record.status == "active"} label="Activar/Desactivar" />
                    </Col>
                </Row>
                <Content>
                    <Row gutter={0} type="flex" justify="space-between" align="top">
                        <Col xl={8} lg={8} md={24} xs={24}>
                            <Row gutter={8}>
                                <Col>
                                    <PropertyGallery
                                        record={record}
                                        dataSource={record.media || []}
                                        onChange={handleOnChange} />
                                </Col>
                                {<Col>
                                    <Box title="Galería">
                                        <PropertyGalleryUploader
                                            record={record}
                                            dataSource={record.media || []}
                                            listType="picture-card"
                                            onChange={() => {
                                                getData();
                                            }}
                                        />
                                    </Box>
                                </Col>}
                            </Row>
                        </Col>
                        <Col xl={8} lg={6} md={24} xs={24}>
                            <Row >
                                <Box title="Precios">
                                    <PricesForm record={record} onChange={handleOnChange} />
                                </Box>
                                <Box title="Información General">
                                    <GeneralInformationForm record={record} onChange={handleOnChange} />
                                </Box>
                                <Box title="Ubicación">
                                    <UbicationForm record={record} onChange={handleOnChange} />
                                </Box>
                                <Box title="Notas Privadas">
                                    <NotesForm record={record} onChange={handleOnChange} />
                                </Box>
                            </Row>
                        </Col>
                        <Col xl={8} lg={8} md={24} xs={24}>
                            <Row >
                                {record && <Box title="Contactos">
                                    <ContactsForm
                                        onNew={() => handleNew("contact")}
                                        onEdit={(id) => handleEdit("contact", id)}
                                        onUpdate={() => {
                                            getData();
                                        }}
                                        record={record}
                                        contacts={record.contacts}
                                        onChange={handleOnChange}
                                    />
                                </Box>}
                                <Box title="Terceros">
                                    <ThirdPartiesForm
                                        onNew={() => handleNew("thirdparties")}
                                        onEdit={(id) => handleEdit("thirdparties", id)}
                                        record={record}
                                        onUpdate={() => {
                                            getData();
                                        }}
                                        contacts={record.third_party_contacts}
                                        onChange={handleOnChange}
                                    />
                                </Box>
                                <Box title="AMENIDADES">
                                    <AmenitiesForm record={record} onChange={handleOnChange} />
                                </Box>
                                <Box title="Otros">
                                    <OthersForm record={record} onChange={handleOnChange} />
                                </Box>
                            </Row>
                        </Col>
                    </Row>
                </Content>
            </Layout> :
                <Loader />
            }
            <SideBar
                title={action == "contact" ? `${contact_id ? "Editar" : "Crear"} Contacto` : `${contact_id ? "Editar" : "Crear"} Tercero`}
                width={"20%"}
                onClose={() => setAction(null)}
                visible={action}
            >
                <>
                    {action == "contact" ?
                        <>
                            <ContactForm
                                id={contact_id}
                                record={record}
                                onSubmit={() => {
                                    setAction(null);
                                    getData();
                                }} />
                        </> :
                        <>
                            <ThirdPartyContactForm
                                id={contact_id}
                                record={record}
                                onSubmit={() => {
                                    setAction(null);
                                    getData();
                                }} />
                        </>
                    }
                </>
            </SideBar>
        </Wrapper>
    </>
    );
}
export default PropertyShow;